<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='关联订单号'>
            <a-input placeholder="关联订单号" v-model="searchForm.id"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='网点'>
            <a-input placeholder="网点" v-model="searchForm.organizationName"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='修理厂'>
            <a-input placeholder="修理厂" v-model="searchForm.customerName"/>
          </a-form-item>
        </a-col>
        <a-col
            :span="6"
            :style="{ display: expand ? 'block' : 'none' }"
        >
          <a-form-item label='建立时间'>
            <a-range-picker v-model="searchForm.createdAt" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="($root.$data.privateState.action.includes('/InvoiceImage/TableList') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))">
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick">
            查询
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="expand = !expand">
            {{expand ? '收起' : '展开'}} <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    
    
    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      <a-row style="padding-bottom:16px" type="flex" justify="end" :gutter="24">
        <a-col>
          <a-button icon="plus" type="primary" @click="addClick">增加发票上传</a-button>
        </a-col>
      </a-row>
      <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-col>
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="column-height" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'default' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'default'">默认</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'middle' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'middle'">中等</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'small' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'small'">紧凑</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="() => {screenfull.toggle();isFullscreen=!isFullscreen}"/>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" icon="reload" @click="searchClick" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group :value="showColumns" @change="changeColumns">
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{it}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="setting" />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        v-if="($root.$data.privateState.action.includes('/InvoiceImage/TableList') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))"
        :columns="columns"
        :rowKey="record => record.id"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 2300 }"
        @change="handleTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #id="id, data">
          <a-tag v-if="data.differenceTransferAmount == 0" color="green">{{id}}</a-tag>
          <a-tag v-else color="orange">{{id}}</a-tag>
        </template>
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template #orderUuids="orderUuids">
          <a-tag v-for="it in orderUuids" :key="it" @click="toOrderDetail(it, 'uuid')">{{it}}</a-tag>
        </template>
        <template #plateNo="plateNo, data">
          <a-tag v-for="(it, index) in plateNo" :key="`${it}_${index}`" :color="data['doneOrderPlateNo'].indexOf(it) > -1 ? 'green': ''" @click="toOrderDetailByUuids(data)">{{it}}</a-tag>
        </template>
        <template #action="data">
          <a-button type="link" @click="showImageHandle(data)">查看</a-button>
          <a-popconfirm
            v-if="($root.$data.privateState.action.includes('/InvoiceImage/DeleteItem') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))"
            title="是否确认删除该项?"
            ok-text="是"
            cancel-text="否"
            @confirm="handleDelete(data)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
          <!-- <a-button v-if="data.status === 1" type="link" @click="editClick(data)">编辑</a-button>
          <a-button v-if="data.status === 1" type="link" @click="uploadSingleImageClick(data.flowNo)">上传图片</a-button> -->
        </template>
      </a-table>
    </div>
    <!-- table end -->
    <a-modal
      v-model="showUploadImageModal"
      @ok="uploadImageOk"
      @cancel="showUploadImageModal = false"
      title="上传发票"
      width="90%"
    >
      <div>
        <a-row>
          <a-col>
            <a-input-search enter-button addon-before="搜索车牌号或修理厂名称绑定关联订单" v-model="searchPlateNo" @search="onSearchPlateNo"/>
          </a-col>
          <a-col>
            <a-input addon-before="发票金额" v-model="invoiceAmount"/>
          </a-col>
          <el-table
            stripe
            :data="bindOrders"
            style="width: 100%">
            <el-table-column
              prop="uuid"
              label="订单号">
            </el-table-column>
            <el-table-column
              prop="plateNo"
              label="车牌号">
            </el-table-column>
            <el-table-column
              prop="repairShop"
              label="修理厂">
            </el-table-column>
            <el-table-column
              prop="transferAmount"
              label="转款金额">
            </el-table-column>
            <el-table-column
              prop="costInfosAmount"
              label="费用合计">
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="120">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="removeBindOrders(scope.$index, bindOrders)"
                  type="text"
                  size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <a-col>
            <a-divider orientation="left">发票图片</a-divider>
            <a-upload
              list-type="picture-card"
              :defaultFileList="imageFiles"
              :file-list="imageFiles"
              :multiple="true"
              :before-upload="beforeUpload"
              :remove="handleRemove"
              @preview="handlePreview"
            >
              <div v-if="imageFiles.length < 10">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
          </a-col>
          <a-divider>备注</a-divider>
          <a-col>
            <a-textarea placeholder="填写备注" :rows="2" v-model="remarks" />
          </a-col>
          <a-divider>付款信息</a-divider>
        <div>
          <a-col :span="8">
            赔款付款账号
             <a-select
              style="width:100%"
                  notFoundContent="请先选择"
                  :allowClear="true"
                  v-model="paymentBankCard"
                >
                  <a-select-option
                    v-for="it in paymentBankList"
                    :key="it.bankCard"
                    >
                    <a-tag v-if="it.type === 'company'" color="blue">公户</a-tag>
                    <a-tag v-else-if="it.type === 'private'" color="purple">私户</a-tag>
                    {{ it.bankAccount }}({{it.bankCard}})
                  </a-select-option>
                </a-select>
            </a-col>
            <a-col :span="8" style="margin-left: 10px">
              费用付款账户
              <a-select
                  style="width:100%"
                  notFoundContent="请先选择"
                  :allowClear="true"
                  v-model="paymentBankCardByPrivate"
                >
                  <a-select-option
                    v-for="it in paymentBankList"
                    :key="it.bankCard"
                    >
                    <a-tag v-if="it.type === 'company'" color="blue">公户</a-tag>
                    <a-tag v-else-if="it.type === 'private'" color="purple">私户</a-tag>
                    {{ it.bankAccount }}({{it.bankCard}})
                  </a-select-option>
                </a-select>
            </a-col>
        </div>
        </a-row>
      </div>
    </a-modal>

    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <div v-viewer>
        <img alt="example" style="width: 100%" :src="previewImage" />
      </div>
    </a-modal>
    <a-modal
      :width="200"
      :visible="sending"
      :closable="false"
      :maskClosable="false"
      :footer="null"
    >
      <a-row>
        <a-col style="text-align: center;">
          <a-icon type="loading" :style="{ fontSize: '36px', color: '#1890ff' }" />
        </a-col>
        <a-col style="text-align: center;color:#1890ff;padding-top:10px;">
          {{sendingHint}}
        </a-col>
      </a-row>
    </a-modal>
    <a-modal :visible="searchOrdersVisible" :footer="null" @cancel="searchOrdersVisible = false">
      <a-table
          :columns="searchOrdersColumns"
          :rowKey="record => record.uuid"
          :dataSource="searchOrders"
          :scroll="{ x: 750 }"
          bordered
      >
        <template #action="data">
          <a-button type="link" @click="selectSearchOrderHandle(data)">选择</a-button>
        </template>
      </a-table>
    </a-modal>

    <a-modal :visible="showDetail" :footer="null" @cancel="showDetail = false">
      <div v-if="detailData">
        <a-divider orientation="left">关联订单号</a-divider>
        <a-tag v-for="it in detailData.orderUuids" :key="it" @click="toOrderDetail(it, 'uuid')">{{it}}</a-tag>
        <a-divider orientation="left">关联车牌</a-divider>
        <a-tag v-for="it in detailData.plateNo" :key="it" @click="toOrderDetail(it, 'plateNo')">{{it}}</a-tag>
        <div v-if="detailData.invoiceAmount">
          <a-divider orientation="left">填写发票金额</a-divider>
          <a-tag>{{detailData.invoiceAmount}}</a-tag>
        </div>
        <a-divider orientation="left">发票文件(点击查看)</a-divider>
        <a-list bordered :data-source="detailData.images">
          <a-list-item slot="renderItem" slot-scope="item">
            <a-button type="link" @click="windowOpen(item[1])">{{item[0]}}</a-button>
          </a-list-item>
        </a-list>
        <!-- <div v-viewer>
          <div v-for="img in detailData.images" :key="img">
            
            <img v-for="img in detailData.images" :key="img"
                  height="100px"
                  alt="图片"
                  :src="`${img}`"
              />
          </div>
              <img v-for="img in detailData.images" :key="img"
                  height="100px"
                  alt="图片"
                  :src="`${img}`"
              />
        </div> -->
      </div>
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import * as qiniu from 'qiniu-js'
import moment from 'moment'
import screenfull from 'screenfull'
import { InvoiceImage, AssessDamageOrder, AppPaymentAccount, Utils } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const INIT_SEARCH_FORM = '{"id":"","createdAt":[],"customerName":"","organizationName":""}'
const columns = [
  {
    title: 'ID',
    width: 200,
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: '图片关联订单号',
    
    dataIndex: 'orderUuids',
    scopedSlots: { customRender: 'orderUuids' },
  },
  {
    title: '图片关联车牌号',
    
    dataIndex: 'plateNo',
    scopedSlots: { customRender: 'plateNo' },
  },
  {
    title: '发票金额(填写)',
    dataIndex: 'invoiceAmount',
  },
  {
    title: '网点',
    dataIndex: 'organizationName',
  },
  {
    title: '修理厂',
    dataIndex: 'customerName',
  },
  {
    title: '备注',
    
    dataIndex: 'remarks',
  },
  {
    title: '导入时间',
    width: 150,
    dataIndex: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 130,
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  }
]
export default {
  name: 'InvoiceImageTableList',
  mixins: [aliveVM, mixinTableList],
  async created () {
    const baseColumns = []
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    this.$set(this, 'baseColumns', baseColumns)
    this.fetch()
  },
  async mounted() {
    await this.getPaymentBankList()
  },
  computed: {
    showColumns() {
      let c = []
      for (const it of this.columns) {
        c.push(it.title)
      }
      return c
    }
  },
  data () {
    return {
      sending: false,
      sendingHint: '',
      // table使用
      screenfull,
      isFullscreen: false,
      expand: false,
      tableListApi: `${InvoiceImage}/TableList`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      baseColumns: [],
      columns,
      rowKey: 'uuid',
      // 其他
      moment,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      paymentBankList: [],
      // 上传
      paymentBankCard: '',
      paymentBankCardByPrivate: '',
      invoiceAmount: 0,
      remarks: '',
      showUploadImageModal: false,
      searchPlateNo: '',
      imageFiles: [],
      bindOrders: [],
      searchOrders: [],
      searchOrdersVisible: false,
      actionMode: 'create',
      searchOrdersColumns: [
        {
          title: '操作',
          key: 'operation',
          width: 200,
          scopedSlots: { customRender: 'action' },
        },
        {
          title: '订单号',
          width: 150,
          dataIndex: 'uuid',
        },
        {
          title: '车牌号',
          width: 150,
          dataIndex: 'plateNo',
        },
        {
          title: '修理厂',
          width: 150,
          dataIndex: 'repairShop',
        },
        {
          title: '定损金额',
          width: 150,
          dataIndex: 'damageAmount',
        },
        {
          title: '转款金额',
          width: 150,
          dataIndex: 'transferAmount',
        },
        {
          title: '费用合计',
          width: 150,
          dataIndex: 'costInfosAmount',
        },
      ],
      // 新增
      previewVisible: false,
      previewImage: '',
      // 详情
      showDetail: false,
      detailData: null
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', null)
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', null)
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      if (this.loading) return;
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        let it = searchForm[k]
        if (k === 'transactionTime') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (k === 'status') {
          it = parseInt(it)
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    // 其他
    async getPaymentBankList() {
      const apiRes = await this.$axios
        .get(`${AppPaymentAccount}/SelectList`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return null;
      }
      this.paymentBankList = apiRes.data
    },
    beforeUpload(file) {
      file.status = 'done'
      this.imageFiles = [...this.imageFiles, file]
      return false;
    },
    handleRemove(file) {
      const index = this.imageFiles.indexOf(file);
      const newFileList = this.imageFiles.slice();
      newFileList.splice(index, 1);
      this.imageFiles = newFileList;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChangeFile (info) {
      this.uploadList = [info.fileList[info.fileList.length - 1]]
      if (info.file.status === 'error') {
        this.$message.error('上传文件失败，请尝试重新登录');
        return
      } else if (info.file.status === 'done') {
        this.errorList = []
        if (!info.file.response.status) {
          this.$message.error(info.file.response.message);
          if (info.file.response.data) {
            this.errorList = info.file.response.data
            this.showErrorModal = true
          }
          return;
        }
        if (info.file.response.data && (typeof info.file.response.data) === 'object' && info.file.response.data.length > 0) {
          this.errorList = info.file.response.data
          this.showErrorModal = true
        } else {
          this.$message.success('导入数据成功')
        }
        this.fetch();
      }
    },
    async handleDelete (data) {
      const res = await this.$axios.delete(`${InvoiceImage}/DeleteItem?id=${data.id}`)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        if (!res.status) {
          this.$message.error(res.message)
            return
        }
        this.addFormModalShow = false
        this.$message.success('操作成功')
        this.fetch()
    },
    removeBindOrders(index, rows) {
      rows.splice(index, 1)
    },
    async onSearchPlateNo(value) {
      if (!value) {
        this.$message.error("请输入车牌查询")
        return
      }
      const bindOrders = [];
      for (const it of this.bindOrders) {
        bindOrders.push(it.uuid)
      }
      const apiRes = await this.$axios.post(`${AssessDamageOrder}/GetSearchListByPlateNo`, {
        key: value,
        bindOrders: bindOrders
      })
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(`获取数据错误：${apiRes.message}`)
        return
      }
      if (licia.isArr(apiRes.data) && apiRes.data.length > 0) {
        const datas = [];
        for (const it of apiRes.data) {
          let costInfosAmount = 0
          for (const bf of it.costInfos) {
            if (!bf.amount) continue;
            costInfosAmount = Math.round((costInfosAmount + bf.amount) * 100) / 100
          }
          it.costInfosAmount = costInfosAmount
          datas.push(it)
        }
        this.searchOrders = datas
        this.searchOrdersVisible = true
        return
      }
      this.$message.error('没有查询到数据')
    },
    selectSearchOrderHandle(data) {
      for (const it of this.bindOrders) {
        if (it.uuid == data.uuid) {
          this.$message.error(`${it.flowNo}已选择`)
          return
        }
      }
      this.bindOrders.push(JSON.parse(JSON.stringify(data)))
      this.searchOrdersVisible = false
    },
    // 新增
    async uploadImageOk() {
      // 数据校验
      if (!this.bindOrders || this.bindOrders.length < 1) {
        this.$message.error("请选择绑定订单")
        return;
      }
      if (!this.imageFiles || this.imageFiles.length < 1) {
        this.$message.error("请选择发票图片")
        return;
      }
      let bindOrderTransferAmount = 0
      let bindOrderCostInfosAmount = 0
      for (const it of this.bindOrders) {
        bindOrderTransferAmount += it.transferAmount
        bindOrderCostInfosAmount += it.costInfosAmount
      }
      console.log(bindOrderCostInfosAmount)
      const invoiceAmount = Math.round(parseFloat(this.invoiceAmount) * 100) / 100
      let differenceTransferAmount = Math.round((invoiceAmount - bindOrderTransferAmount) * 100) / 100;
      console.log(differenceTransferAmount)
      if (differenceTransferAmount > 50 || differenceTransferAmount < -50) {
        this.$message.error("您填写的发票金额，和实际选中的订单转款金额合计差额过大")
        return;
      }
      if (differenceTransferAmount != 0 && !this.remarks) {
        this.$message.error("您填写的发票金额，和实际选中的订单转款金额合计不一致，必须填写备注")
        return;
      }

      this.sending = true
      this.sendingHint = '正在获取图片上传凭证'
      const imagesUploadToken = await this.getImageUploadToken(this.imageFiles.length)
      if (!imagesUploadToken) {
        return
      }
      const uploadedImages = [];
      for (let index = 0; index < this.imageFiles.length; index++) {
        const file = this.imageFiles[index];
        var sub = await this.uploadImageObserver(file, imagesUploadToken[index].imageName, imagesUploadToken[index].token)
          .catch(() => null);
        if (sub != null){
          uploadedImages.push(sub.key)
        }
      }
      if (uploadedImages.length < 1) {
        this.sending = false
        this.$message.error("图片上传失败")
        return;
      }

      const addInvoiceRes = await this.$axios.post(`${InvoiceImage}/AddInvoice`, {
        bindOrders: this.bindOrders,
        imageKeys: uploadedImages,
        differenceTransferAmount,
        remarks: this.remarks,
        paymentBankCard: this.paymentBankCard,
        paymentBankCardByPrivate: this.paymentBankCardByPrivate,
        invoiceAmount: this.invoiceAmount
      }).catch(e => {
        this.showUploadImageModal = false
        return { status: false, message: e.message.indexOf('401') > -1 ? '登录超时' : e.message }
      })
      this.sending = false
      if (!addInvoiceRes.status) {
        this.showUploadImageModal = false
        this.$notification.error({ message: '上传数据错误', description: addInvoiceRes.message })
        return
      }
      this.showUploadImageModal = false
      this.$message.success('发票上传成功')
      this.fetch()
    },
    uploadImageObserver(file, key, token)
    {
      return new Promise((resolve, reject) => {

        const options = {
          quality: 0.7,
          noCompressIfLarger: true,
          maxWidth: 1000,
          maxHeight: 1000
        }
        if (file.type.indexOf('image') > -1) {
          qiniu.compressImage(file, options).then(data => {
            const observable = qiniu.upload(data.dist, key, token, null, {region: 'qiniu.kuaian.vip', useCdnDomain: true, uphost: 'up-z2.qiniup.com', upprotocol: 'https', forceDirect: true})
            observable.subscribe({
              complete(res) {
                resolve(res)
              },
              error(err) {
                reject(err)
              }
            }) // 上传开始
          })
        } else {
          const observable = qiniu.upload(file, key, token, null, {region: 'qiniu.kuaian.vip', useCdnDomain: true, uphost: 'up-z2.qiniup.com', upprotocol: 'https', forceDirect: true})
            observable.subscribe({
              complete(res) {
                resolve(res)
              },
              error(err) {
                reject(err)
              }
            }) // 上传开始
        }
        
      })
    },
    async getImageUploadToken(imageCount) {
      const apiRes = await this.$axios.get(`${Utils}/GetQiNiuUploadTokenList?imageCount=${imageCount}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(`获取数据错误：${apiRes.message}`)
        return
      }
      return apiRes.data;
    },
    addClick () {
      this.actionMode = 'create'
      this.$set(this, 'imageFiles', [])
      this.searchPlateNo = ''
      this.bindOrders = []
      this.invoiceAmount = 0
      this.remarks = ''
      this.showUploadImageModal = true
      this.paymentBankCard = ''
      this.paymentBankCardByPrivate = ''
      
    },
    async showImageHandle (data) {
      this.$set(this, 'detailData', {})
      const res = await this.$axios.get(`${InvoiceImage}/ShowImage?id=${data.id}`)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        if (!res.status) {
          this.$message.error(res.message)
            return
        }
      this.$set(this, 'detailData', {
        orderUuids: data.orderUuids,
        plateNo: data.plateNo,
        invoiceAmount: data.invoiceAmount,
        images: res.data
      })
      this.showDetail = true
    },
    toOrderDetail(key, type) {
      this.$router.push({ name: 'AssessDamageOrder', params: {key, type} }).catch(err => err)
    },
    toOrderDetailByUuids(data) {
      this.$router.push({ name: 'AssessDamageOrder', params: {key: data["orderUuids"], type: "uuids"} }).catch(err => err)
    },
    windowOpen(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style>

</style>